<template>
  <nuxt-link
    :to="{
      name: 'search-type',
      params: {
        type: $t('router.search_type.type.country'),
        value: slug,
        tab: $t('router.search_type.tab.tours'),
      },
    }"
    class="country-link"
    @click.native="handleLoading"
  >
    {{ $t(`${base}.trips_to`, { name }) }}
  </nuxt-link>
</template>

<script>
export default {
  name: 'LadHomeDestinationCountry',
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    alpha_3_code: { type: String, required: true },
    slug: { type: String, required: true },
    name: { type: String, required: true },
    base: { type: String, required: true },
  },
  methods: {
    handleLoading() {
      this.$nuxt.$emit('custom-loading', { enabled: true, source: 'search' })
    },
  },
}
</script>
